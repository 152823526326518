import React from 'react';
import SimulationForm from './SimulationForm';

function App() {
  return (
    <div className="container mt-5">
      <h1 className="text-center">Can We Retire?</h1>
      <SimulationForm />
    </div>
  );
}

export default App;