import React, { useState } from 'react';
import axios from 'axios';

function SimulationForm() {
  const [formData, setFormData] = useState({
    initial_balance: 100000,
    annual_return: 6.5,
    annual_contribution: 10000,
    years_to_retirement: 30,
    num_simulations: 1000,
  });
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: parseFloat(e.target.value),
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post('/api/simulate/', formData)
      .then(response => {
        setResults(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error running the simulation!', error);
        setLoading(false);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* Input Fields */}
        {['initial_balance', 'annual_contribution', 'annual_return', 'years_to_retirement', 'num_simulations'].map((field, idx) => (
          <div className="mb-3" key={idx}>
            <label className="form-label">{field.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}:</label>
            <input
              type="number"
              name={field}
              value={formData[field]}
              onChange={handleChange}
              className="form-control"
              step="any"
            />
          </div>
        ))}
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Running Simulation...' : 'Run Simulation'}
        </button>
      </form>

      {results && (
        <div className="mt-5">
          <h3>Results</h3>
          <p>Success Rate: {(results.success_rate * 100).toFixed(2)}%</p>
          <p>Median Ending Balance: ${results.median_ending_balance.toFixed(2)}</p>
        </div>
      )}
    </div>
  );
}

export default SimulationForm;
